import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private api:Api) { }

  getBookingList(limit = 0, skip = 0,filter: any={}) {
    let endpoint = 'bookings/view-all/?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit+ '&';
    }
    if(filter?.searchByName){
      endpoint += 'search=' + filter?.searchByName+ '&';
    }
    if(filter?.bookingDateFrom){
      endpoint += 'bookingDateFrom=' + filter?.bookingDateFrom+ '&';
    }
    if(filter?.bookingDateTo){
      endpoint += 'bookingDateTo=' + filter?.bookingDateTo+ '&';
    }
    if(filter?.checkIn){
      endpoint += 'checkIn=' + filter?.checkIn+ '&';
    }
    if(filter?.checkOut){
      endpoint += 'checkOut=' + filter?.checkOut+ '&';
    }
    if(filter?.room){
      endpoint += 'condition[roomId]=' + filter?.room+ '&';
    }
    if(filter?.bookingStatus){
      endpoint += 'condition[bookingStatus]=' + filter?.bookingStatus+ '&';
    }
    if(filter?.type){
      endpoint += 'type=' + filter?.type+ '&';
    }
    if(filter?.invoiceGenerated){
      endpoint += 'invoiceGenerated=1';
    }

    return this
      .api
      .get(endpoint);
  }

  getDiscountList(limit = 0, skip = 0,filter: any={}) {
    let endpoint = 'transaction/discounts/?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit+ '&';
    }
    if(filter?.searchByName){
      endpoint += 'search=' + filter?.searchByName+ '&';
    }
    if(filter?.bookingDateFrom){
      endpoint += 'bookingDateFrom=' + filter?.bookingDateFrom+ '&';
    }
    if(filter?.bookingDateTo){
      endpoint += 'bookingDateTo=' + filter?.bookingDateTo+ '&';
    }
    if(filter?.checkIn){
      endpoint += 'checkIn=' + filter?.checkIn+ '&';
    }
    if(filter?.checkOut){
      endpoint += 'checkOut=' + filter?.checkOut+ '&';
    }
    if(filter?.room){
      endpoint += 'condition[roomId]=' + filter?.room+ '&';
    }
    if(filter?.bookingStatus){
      endpoint += 'condition[bookingStatus]=' + filter?.bookingStatus+ '&';
    }
    if(filter?.type){
      endpoint += 'type=' + filter?.type+ '&';
    }
    if(filter?.invoiceGenerated){
      endpoint += 'invoiceGenerated=1';
    }

    return this
      .api
      .get(endpoint);
  }

  getDiscountView(id){
    let endpoint='transaction/discount/'+id
    return this
    .api
    .get(endpoint)
  }

  getRefundList(limit = 0, skip = 0,filter: any={}) {
    let endpoint = 'transaction/refunds/?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit+ '&';
    }
    if(filter?.searchByName){
      endpoint += 'search=' + filter?.searchByName+ '&';
    }
    if(filter?.bookingDateFrom){
      endpoint += 'bookingDateFrom=' + filter?.bookingDateFrom+ '&';
    }
    if(filter?.bookingDateTo){
      endpoint += 'bookingDateTo=' + filter?.bookingDateTo+ '&';
    }
    if(filter?.checkIn){
      endpoint += 'checkIn=' + filter?.checkIn+ '&';
    }
    if(filter?.checkOut){
      endpoint += 'checkOut=' + filter?.checkOut+ '&';
    }
    if(filter?.room){
      endpoint += 'condition[roomId]=' + filter?.room+ '&';
    }
    if(filter?.bookingStatus){
      endpoint += 'condition[bookingStatus]=' + filter?.bookingStatus+ '&';
    }
    if(filter?.type){
      endpoint += 'type=' + filter?.type+ '&';
    }
    if(filter?.invoiceGenerated){
      endpoint += 'invoiceGenerated=1';
    }

    return this
      .api
      .get(endpoint);
  }

  getRefundView(id){
    let endpoint='transaction/refund/'+id
    return this
    .api
    .get(endpoint)
  }

  exportBooking(filter: any={}) {
    let endpoint = 'excel-export/booking?';
    if(filter?.searchByName){
      endpoint += 'search=' + filter?.searchByName+ '&';
    }
    if(filter?.bookingDateFrom){
      endpoint += 'bookingDateFrom=' + filter?.bookingDateFrom+ '&';
    }
    if(filter?.bookingDateTo){
      endpoint += 'bookingDateTo=' + filter?.bookingDateTo+ '&';
    }
    if(filter?.checkIn){
      endpoint += 'checkIn=' + filter?.checkIn+ '&';
    }
    if(filter?.checkOut){
      endpoint += 'checkOut=' + filter?.checkOut+ '&';
    }
    if(filter?.room){
      endpoint += 'condition[roomId]=' + filter?.room+ '&';
    }
    if(filter?.bookingStatus){
      endpoint += 'condition[bookingStatus]=' + filter?.bookingStatus+ '&';
    }

    return this
      .api
      .get(endpoint);
  }


  getBookingView(id){
    let endpoint='bookings/view/'+id
    return this
    .api
    .get(endpoint)
  }

  addBooking(data){
    let endpoint='booking/add'
    return this
    .api
    .post(endpoint,data)
  }

  addTransaction(data){
    let endpoint='transaction/payment/add'
    return this
    .api
    .post(endpoint,data)
  }

  addFoodDetails(data,isEdit:any=false,id:any=null){
    if(!isEdit){
    let endpoint='booking/expense/add'
    return this
    .api
    .post(endpoint,data)
    }else{
      let endpoint='booking/expense/'+id
      return this
      .api
      .put(endpoint,data)
    }
  }

  getTransactions(oId:any=null){
    let endpoint='transaction/view-all/payments'
    if(oId !=null)endpoint=endpoint+'?condition[booking]='+oId
    return this
    .api
    .get(endpoint)
  }

  checkIn(data,id){
    let endpoint='booking/'+id
    let fData = new FormData();
    for (var key in data) {
        fData.append(key, data[key]);
    }
    return this
    .api
    .postAsFormData(endpoint,fData)
  }

  updateBooking(data,id){
    const endpoint='booking/'+id
    return this
    .api
    .postAsFormData(endpoint,data)
  }

  generateInvoice(data,id){
    const endpoint='booking/invoiceGen/'+id
    return this
    .api
    .postReqAsFormData(endpoint,data)
  }


  getExpenseBooking(oId:any=null){
    let endpoint='bookings/expense/view-all'
    if(oId !=null)endpoint=endpoint+'?condition[booking]='+oId
    return this
    .api
    .get(endpoint)
  }

  checkOut(data,id){
    let endpoint='booking/'+id
    return this
    .api
    .put(endpoint,data)
  }


  getBookingRequests(limit = 0, skip = 0) {
    let endpoint = 'booking-requests/view-all?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit;
    }
    return this
      .api
      .get(endpoint);
  }

  getBookingRequestView(id){
    let endpoint='booking-requests/view/'+id
    return this
    .api
    .get(endpoint)
  }

  updateBookingRequest(data,id){
    let endpoint='booking-request/'+id
    return this
    .api
    .put(endpoint,data)
  }
  getBanks(){
    let endpoint='bank-detail/view-all'
    return this
    .api
    .get(endpoint)
  }

  getFoodList(limit = 0, skip = 0) {
    let endpoint = 'bookings/expense/view-all?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit;
    }
    return this
      .api
      .get(endpoint);
  }
  getExpenceTotal(id) {
    let endpoint='bookings/expense-total/'+id
    return this
    .api
    .get(endpoint)
  }

}
