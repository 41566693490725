import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { BookingService } from '../../providers/booking.service';
import { CommonService } from '../../providers/common';
import { EventPublishService } from '../../providers/event';

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.page.html',
  styleUrls: ['./check-out.page.scss'],
})
export class CheckOutPage implements OnInit {


  checkout: any;
  bookingData: any = [];
  dueAmount: any;
  payment_mode: any;
  isDue = false;
  isSpinner = false;
  formType = 'normal';

  checklists: any = [
    {
      title: 'Bed and linens ',
      answer: false
    },
    {
      title: 'Bathroom Fittings',
      answer: false
    },
    {
      title: 'Extra blanket and pillows. ',
      answer: false
    },
    {
      title: 'Towels',
      answer: false
    },
    {
      title: 'Furniture',
      answer: false
    },
    {
      title: 'Trash Can',
      answer: false
    },
    {
      title: 'Telivision and Remote',
      answer: false
    },
    {
      title: 'Light\'s',
      answer: false
    },
    {
      title: 'Kitchen Utensils',
      answer: false
    },
    {
      title: 'Windows and Glass',
      answer: false
    },

  ];

  constructor(private navParams: NavParams,
    private datePipe: DatePipe,
    private bookingService: BookingService,
    private modalCtrl: ModalController,
    private common: CommonService,
    private event: EventPublishService) {
    if (this.navParams.get('booking')) {
      this.bookingData = this.navParams.get('booking');
      this.checkout = this.datePipe.transform(this.bookingData.checkOut, 'yyyy-MM-dd');
    }
    if (this.navParams.get('type')) {
      this.formType = this.navParams.get('type');
    }
  }

  get checklistValidation() {
    if(this.bookingData?.checkOutCheckList?.length>0){
      return true;
    }
    let validation = true;
    this.checklists.forEach(element => {
      if (!element.answer) {
        validation = false;
      }
    });
    return validation;
  }

  ngOnInit() {
    // this.getDue();
    this.dueAmount=this.bookingData?.balanceAmount;
  }


  getDue() {
    this.dueAmount = ((this.bookingData?.bookingAmount + this.bookingData?.totalExpenseAmount) - this.bookingData.paidAmount);
  }


  Submit() {
    if (this.dueAmount > 0) {
      const data = {
        amount: this.dueAmount.toString(),
        paymentMode: this.payment_mode,
        date: this.bookingData.checkOut,
        booking: this.bookingData._id
      };

      this.bookingService.addTransaction(data).subscribe(resp => {
        if (resp.status == 'Success') {
          this.isSpinner = true;
          const data1 = {
            bookingStatus: 'CHECKED_OUT',
            checkIn: this.bookingData.checkIn,
            address: this.bookingData.address,
            phone: this.bookingData.phone,
            whatsappNo: this.bookingData.whatsappNo,
            noOfAdults: this.bookingData.noOfAdults,
            noOfKids: this.bookingData.noOfKids,
            actionType:'CHECKED_OUT'
          };
          this.bookingService.checkOut(data1, this.bookingData._id).subscribe(resp => {
            if (resp.status == 'Success') {
              this.isSpinner = false;
              this.modalCtrl.dismiss();
            }
          });
        }
      });
    } else {
      this.isSpinner = true;
      const data1 = {
        bookingStatus: 'CHECKED_OUT',
        checkIn: this.bookingData.checkIn,
        address: this.bookingData.address,
        phone: this.bookingData.phone,
        whatsappNo: this.bookingData.whatsappNo,
        noOfAdults: this.bookingData.noOfAdults,
        noOfKids: this.bookingData.noOfKids,
        actionType:'CHECKED_OUT'
      };
      this.bookingService.checkOut(data1, this.bookingData._id).subscribe(resp => {
        if (resp.status == 'Success') {
          this.event.publishSomeData('booking');
          this.isSpinner = false;
          this.modalCtrl.dismiss();
        }
      });
    }

    if (this.isDue) {

    }
  }

  saveChecklist() {
    this.isSpinner = true
    const data: any = {
      checkOutCheckList: this.checklists,
      actionType:'CHECKOUT_CHECKLIST'
    };
    this.bookingService.updateBooking(data, this.bookingData._id).subscribe(resp => {
      if (resp.status === 'Success') {
        this.isSpinner = false;
        this.modalCtrl.dismiss();
        this.common.presentToast('Successfully Updated');
      } else {
        this.common.presentToast('Something Went Wrong');
      }
    }, error => {
      this.common.processError(error);
      this.isSpinner = false;
    });
  }


  closeModel() {
    this.modalCtrl.dismiss();
  }
}
