import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { AddBookingPage } from './booking/add-booking/add-booking.page';
import { BookingAddPaymentPage } from './booking/booking-add-payment/booking-add-payment.page';
import { FoodDetailsModalPage } from './booking/food-details-modal/food-details-modal.page';
import { ExpenseDetailsModalPage } from './booking/expense-details-modal/expense-details-modal.page';
import { CheckinPage } from './booking/checkin/checkin.page';
import { CheckOutPage } from './booking/check-out/check-out.page';
import { BookingSuccessPage } from './booking/booking-success/booking-success.page';
import { RoomsAddPage } from './room/rooms-add/rooms-add.page';
import { TaxAddPage } from './tax/tax-add/tax-add.page';
import { ExpenseAddPage } from './expense/expense-add/expense-add.page';
import { FeedbackPage } from './feedback/feedback/feedback.page';
import { FeedbackQuestionsAddComponent } from './feedback/feedback-questions-add/feedback-questions-add.component';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { PDFGenerator } from '@awesome-cordova-plugins/pdf-generator/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AddBookingPage,
    BookingAddPaymentPage,
    FoodDetailsModalPage,
    ExpenseDetailsModalPage,
    CheckinPage,
    CheckOutPage,
    BookingSuccessPage,
    RoomsAddPage,
    TaxAddPage,
    FeedbackPage,
    FeedbackQuestionsAddComponent
  ],
  entryComponents: [
    LoginComponent,
    AddBookingPage,
    BookingAddPaymentPage,
    FoodDetailsModalPage,
    ExpenseDetailsModalPage,
    CheckinPage,
    CheckOutPage,
    BookingSuccessPage,
    RoomsAddPage,
    TaxAddPage,
    FeedbackPage,
    FeedbackQuestionsAddComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe,
    CurrencyPipe,
    Camera,
    FCM,
    SplashScreen,
    PDFGenerator,
    File
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
