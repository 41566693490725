import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { BookingService } from '../../providers/booking.service';
import { EventPublishService } from '../../providers/event';

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-add-payment.page.html',
  styleUrls: ['./booking-add-payment.page.scss'],
})
export class BookingAddPaymentPage implements OnInit {
  paymentForm: any = FormGroup;
  submitted = false;
  bookingData: any = [];
  banks: any = [];
  isSpinner = false;
  isRefund = false;
  bookingAmount: any;

  constructor(
    public fb: FormBuilder,
    private navParams: NavParams,
    private bookingService: BookingService,
    private modalCtrl: ModalController,
    private event: EventPublishService
  ) {}

  get epf() {
    return this.paymentForm.controls;
  }

  ngOnInit() {
    this.loadBanks();
    this.bookingData = this.navParams.get('booking');
    this.paymentForm = this.fb.group({
      amount: [this.bookingData.bookingAmount, Validators.required],
      bookingAmount:this.bookingData.bookingAmount,
      paymentMode: ['CASH', Validators.required],
      date: ['', Validators.required],
      discountAmount: [''],
      bankDetailId: [''],
      comments: [''],
    });
    if (this.navParams.get('booking')) {
      // this.bookingData = this.navParams.get('booking');
      this.bookingAmount = this.bookingData.bookingAmount;
      this.paymentForm.get('date').setValue(this.bookingData.bookingDate);
    }
    if (this.navParams.get('type') === 'refund') {
      this.isRefund = true;
    }
  }

  loadBanks() {
    this.bookingService.getBanks().subscribe((resp) => {
      if (resp.status === 'Success') {
        this.banks = resp.data.lists;
      }
    });
  }

  amountOnChange($event: any) {
    if ($event?.target?.value > this.bookingAmount) {
      this.paymentForm.controls.amount.setValue( this.bookingAmount);
      alert('Amount should not be more the due amount');
    }
  }

  updatePayment() {
    this.submitted = true;
    if (this.paymentForm.valid) {
      this.isSpinner = true;
      const data: any = {
        amount: parseInt(this.epf.amount.value),
        paymentMode: this.epf.paymentMode.value,
        date: this.epf.date.value,
        booking: this.bookingData._id,
        discountAmount: +this.epf.discountAmount.value,
        comments: this.epf.comments.value,
        isRefund: this.isRefund,
      };
      if (
        this.epf.paymentMode.value === 'TRANSFER' &&
        this.epf.bankDetailId.value
      ) {
        data.bankDetailId = this.epf.bankDetailId.value;
      }

      this.bookingService.addTransaction(data).subscribe(
        (resp) => {
          if (resp.status === 'Success') {
            this.event.publishSomeData('booking');
            this.isSpinner = false;
            this.modalCtrl.dismiss();
          }
        },
        (error) => {
          this.isSpinner = false;
        }
      );
    }
  }

  closeModel() {
    this.modalCtrl.dismiss();
  }
}
