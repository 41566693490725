import { Component, QueryList, ViewChildren } from '@angular/core';
import { IonRouterOutlet, MenuController, Platform, AlertController } from '@ionic/angular';
import { UserService } from './providers/user';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { AuthService } from './providers/auth.service';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { Router } from '@angular/router';
import { CommonService } from './providers/common';
import { AdminUserService } from './providers/admin-user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  public appPages = [
    {
      title: 'Booking',
      children: [
        { title: 'Home', url: '/home', icon: 'home-outline', permission: 'VIEW_ALL_BOOKING' },
        { title: 'Booking Request', url: '/booking-request-list', icon: 'git-pull-request-outline', permission: 'VIEW_ALL_BOOKING_REQUEST' },
        { title: 'Booking List', url: '/booking-list/booking', icon: 'list-outline', permission: 'VIEW_ALL_BOOKING' },
        { title: 'CheckIn Checklist', url: '/booking-list/checkin', icon: 'checkmark-done-outline', permission: 'CHECKIN_CHECKLIST_BOOKING' },
        { title: 'CheckOut Checklist', url: '/booking-list/checkout', icon: 'checkmark-done-outline', permission: 'CHECKOUT_CHECKLIST_BOOKING' },
        { title: 'Add Feedback', url: '/booking-list/feedback', icon: 'star-outline', permission: 'CREATE_FEEDBACK' },
        { title: 'Food Request', url: '/booking-food-list', icon: 'beer-outline', permission: 'VIEW_ALL_EXPENSE' },
        { title: 'Breakfast List', url: '/breakfast-list', icon: 'fast-food-outline', permission: 'VIEW_ALL_EXPENSE' },
      ]
    },
    {
      title: 'Finance',
      children: [
        { title: 'Transaction List', url: '/transaction-list', icon: 'card-outline', permission: 'VIEW_ALL_TRANSACTION' },
        // { title: 'Invoice List', url: '/invoice-list', icon: 'card-outline', permission: 'VIEW_ALL_TRANSACTION' },
        { title: 'Discount List', url: '/discount-list', icon: 'list-outline', permission: 'VIEW_ALL_BOOKING' },
        { title: 'Refund List', url: '/refund-list', icon: 'list-outline', permission: 'VIEW_ALL_BOOKING' },
        { title: 'Transfer Request', url: '/transfer-request-list', icon: 'arrow-redo-outline', permission: 'VIEW_ALL_TRANSFER_REQUEST' },
        { title: 'Finance Category', url: '/finance-category', icon: 'filter-outline', permission: 'VIEW_ALL_TRANSACTION_CATEGORY' },
        { title: 'Vendors', url: '/finance-vendors-list', icon: 'people-outline', permission: 'VIEW_ALL_VENDORS' },
        { title: 'Vendor Payout Request', url: '/payout-request-list', icon: 'wallet-outline', permission: 'VIEW_ALL_VENDOR_PAYOUT_REQUEST' },
        { title: 'Bank Details', url: '/bank-detail-list', icon: 'briefcase-outline', permission: 'VIEW_ALL_BANK_DETAIL' },
      ]
    },
    {
      title: 'Feedback',
      children: [
        { title: 'Feedbacks', url: '/feedback-list', icon: 'chatbubble-ellipses-outline', permission: 'VIEW_ALL_FEEDBACK' },
        { title: 'Feedback Question', url: '/feedback-questions-list', icon: 'help-outline', permission: 'VIEW_ALL_FEEDBACK_QUESTION' },
      ]
    },
    {
      title: 'Products',
      children: [
        { title: 'Products', url: '/product-list', icon: 'pricetags-outline', permission: 'VIEW_ALL_PRODUCTS' },
        // { title: 'Product Category', url: '/product-categories', icon: 'filter-outline', permission: 'VIEW_ALL_PRODUCT_CATEGORY' },
        { title: 'Purchase', url: '/purchase-sales-list/PURCHASE', icon: 'podium-outline', permission: 'VIEW_ALL_PURCHASE' },
        { title: 'Stock Out', url: '/purchase-sales-list/SALES', icon: 'podium-outline', permission: 'VIEW_ALL_SALES' },
        { title: 'Purchase Request', url: '/purchase-request', icon: 'cart-outline', permission: 'VIEW_ALL_PURCHASE_REQUEST' },
        // { title: 'Stock', url: '/stock-list', icon: 'podium-outline' }
      ]
    },
    {
      title: 'Settings',
      children: [
        { title: 'Users', url: '/users-list', icon: 'person-outline', permission: 'VIEW_ALL_ADMIN_USER' },
        { title: 'Rooms', url: '/rooms-list', icon: 'business-outline', permission: 'VIEW_ALL_ROOMS' },
        // { title: 'Package', url: '/packages-list', icon: 'business-outline', permission: 'VIEW_ALL_ROOMS' },
        { title: 'Tax', url: '/tax-list', icon: 'business-outline' , permission: 'VIEW_ALL_ROOMS'},
        { title: 'Other Expense', url: '/expense-list', icon: 'business-outline' , permission: 'VIEW_ALL_ROOMS'},
        { title: 'Activity Log', url: '/activity-log', icon: 'lock-closed-outline', permission: 'VIEW_ALL_ACTIVITY_LOG' },
        { title: 'Archive Data', url: '/archive-data', icon: 'archive-outline', permission: 'VIEW_MONTHS' },

      ]
    },
    // {
    //   title: 'Chat',
    //   children: [
    //     { title: 'List', url: '/chat', icon: 'person-outline', permission: 'VIEW_ALL_ADMIN_USER' },
    //     { title: 'View', url: '/chat-view', icon: 'business-outline', permission: 'VIEW_ALL_ROOMS' },

    //   ]
    // },
  ];

  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  constructor(
    public user: UserService,
    public userService: AdminUserService,
    private menu: MenuController,
    private fcm: FCM, private auth: AuthService,
    public platform: Platform,
    private common: CommonService,
    public router: Router,
    public alertCtrl: AlertController,
    public splashScreen: SplashScreen
  ) {
    this.initializeApp()
  }


  ngOnInit() {
    this.user.loadUser();
    this.userService.getMyProfile().subscribe({
      next: (resp: any) => {
        console.log(resp);
        if (resp.status === 'Success') {
          this.user.updateProfile(resp.data);
        }
      },
      error: (error: any) => {
      },
    });

  }

  hasMainPermission(menu) {
    let flag = false;
    menu.children?.forEach(element => {
      if (this.user.hasPermission(element.permission)) {
        flag = true;
        return flag;
      }
    });
    return flag;

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.splashScreen.hide();
      this.registerFCM();
      this.backButtonEvent();
    });
  }

  backButtonEvent() {

    this.platform.backButton.subscribeWithPriority(0, () => {
      this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
        console.log(outlet, outlet.canGoBack(), this.router.url);
        if (outlet && outlet.canGoBack()) {
          outlet.pop();
        } else if (this.router.url !== '/home') {
          this.router.navigateByUrl('/home', { replaceUrl: true })
        }
        else {
          if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {

            navigator['app'].exitApp(); // work in ionic 4

          } else {
            this.common.presentToast('Press again to exit');
            this.lastTimeBackPress = new Date().getTime();
          }
        }
      });
    });
  }

  registerFCM() {
    this.fcm.getToken().then(_token => {
      this.auth.registerFcm({ firebaseId: _token, appType: 'mobile', userType: 'admin' }).subscribe({
        next: (resp: any) => {
        },
        error: (error: any) => {
        },
      });
    });

    this.fcm.onNotification().subscribe(data => {
      console.log('********************')
      console.log(data)
      if (data.wasTapped) {
        // alert(JSON.stringify(data))
        this.onViewNotification(data)
        console.log("Received in background");
      } else {
        console.log("Received in foreground");

        this.presentNotification(data);
      };
    });
    this.fcm.getInitialPushPayload().then((res) => {
      console.log('++++++++++++++Pyload Push **** +++++++++++')
      console.log(res);
      if (res != null) {
        this.onViewNotification(res);
      }
    })
  }

  onViewNotification(_notification) {
    this.router.navigate(['/booking-request-list'])
  }

  async presentNotification(_notification) {
    const alert = await this.alertCtrl.create({
      header: 'Notification',
      subHeader: _notification.title,
      message: _notification.body,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'View',
          handler: () => {
            console.log('Confirm Okay');
            this.onViewNotification(_notification)
          }
        }
      ]
    });


    await alert.present();
  }



  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  openEnd() {
    this.menu.open('end');
  }

  openCustom() {
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
  }

  logout() {
    console.log('logg...')
    this.user.logout();
  }
}
