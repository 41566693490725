import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { BookingService } from '../../providers/booking.service';
import { CommonService } from '../../providers/common';
import { EventPublishService } from '../../providers/event';
import { ExpenseService } from '../../providers/expense.service';

@Component({
  selector: 'app-expense-details-modal',
  templateUrl: './expense-details-modal.page.html',
  styleUrls: ['./expense-details-modal.page.scss'],
})
export class ExpenseDetailsModalPage implements OnInit {
  foodAddForm: any = FormGroup;
  // otherExpenseAddForm
  bookingData: any = [];
  expenseOtherDetails: any = [];
  taxDetails: any = [];
  submitted: boolean = false;
  isSpinner: boolean = false;

  constructor(
    private fb: FormBuilder,
    private navParams: NavParams,
    private expenseOther: ExpenseService,
    private bookingService: BookingService,
    private modalCtrl: ModalController,
    private event: EventPublishService,
    private common: CommonService
  ) {
    if (this.navParams.get('booking')) {
      this.bookingData = this.navParams.get('booking');
    }
  }

  ngOnInit() {
    this.getExpenseOther();

    this.foodAddForm = this.fb.group({
      expenseId: ['', Validators.required],
      quanity: ['', Validators.required],
      amount: ['', Validators.required],
      requestedStatus: ['REQUESTED', Validators.required],
      deliveryTime: [''],
    });
  }

  get epf() {
    return this.foodAddForm.controls;
  }

  getExpenseOther() {
    this.expenseOther.getExpenseOther().subscribe((resp) => {
      if (resp.status == 'Success') {
        this.expenseOtherDetails = resp.data.lists;
      }
    });
  }

  changeExpenseOther(event) {
    const selectedExpenseId = event?.detail?.value;
    this.foodAddForm.controls.expenseId.setValue(selectedExpenseId);

    const taxes = this.expenseOtherDetails.find(
      (t) => t?._id === selectedExpenseId
    );
    // const taxes = this.expenseOtherDetails.filter(
    //   (tax) => tax?._id === event?.detail?.value
    // );
    console.log('taxes', taxes);
    if(taxes){
      this.foodAddForm.controls.amount.setValue(taxes.amount);
    }
    if (taxes && taxes.taxes.length > 0) {
      this.taxDetails = taxes.taxes;
    }
   
    console.log('taxDetails', this.taxDetails);
  }

  submitFooddetails() {
    if (
      this.epf.requestedStatus.value === 'DELIVERED' &&
      !['CHECKED_IN', 'CHECKED_OUT'].includes(this.bookingData.bookingStatus)
    ) {
      this.common.presentToast('You can not mark as delivered before check in');
      return;
    }
    this.submitted = true;
    if (this.foodAddForm.valid) {
      this.isSpinner = true;
      var data: any = {
        expenseId: this.epf.expenseId.value,
        quantity: +this.epf.quanity.value,
        amount: +this.epf.amount.value,
        requestedStatus: this.epf.requestedStatus.value,
        booking: this.bookingData._id,
        mode: 'OTHER',
      };
      if (this.epf.deliveryTime.value) {
        data.expectedDeliveryTime = this.epf.deliveryTime.value + ':00+0530';
      }
      this.bookingService.addFoodDetails(data).subscribe(
        (resp) => {
          if (resp.status == 'Success') {
            this.event.publishSomeData('booking');
            this.isSpinner = false;
            this.modalCtrl.dismiss();
          }
        },
        (error) => {
          this.isSpinner = false;
        }
      );
    }
  }

  closeModel() {
    this.modalCtrl.dismiss();
  }
}
