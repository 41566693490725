import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { BookingService } from '../../providers/booking.service';
import { ExpenseService } from '../../providers/expense.service';
import { CommonService } from '../../providers/common';
import { EventPublishService } from '../../providers/event';

@Component({
  selector: 'app-food-details-modal',
  templateUrl: './food-details-modal.page.html',
  styleUrls: ['./food-details-modal.page.scss'],
})
export class FoodDetailsModalPage implements OnInit {
  foodAddForm: any = FormGroup;
  bookingData: any = [];
  foodDetails: any = [];
  submitted: boolean = false;
  isSpinner: boolean = false;

  constructor(
    private fb: FormBuilder,
    private navParams: NavParams,
    private bookingService: BookingService,
    private foods: ExpenseService,
    private modalCtrl: ModalController,
    private event: EventPublishService,
    private common: CommonService
  ) {
    if (this.navParams.get('booking')) {
      this.bookingData = this.navParams.get('booking');
    }
  }

  ngOnInit() {
    this.getExpenseFood();

    this.foodAddForm = this.fb.group({
      expenseId: ['', Validators.required],
      quanity: ['', Validators.required],
      amount: ['', Validators.required],
      foodDetails: this.fb.array([]),
      requestedStatus: ['REQUESTED', Validators.required],
      deliveryTime: [''],
    });

    this.addFoodDetails();
  }

  get epf() {
    return this.foodAddForm.controls;
  }

  addFoodDetails() {
    const add = this.foodAddForm.get('foodDetails') as FormArray;
    add.push(
      this.fb.group({
        expenseId: ['', Validators.required],
        quantity: ['', Validators.required],
        amount: ['', Validators.required],
      })
    );
  }

  changeFood(form) {
    const selectedExpenseId = form?.detail?.value;

    const foodDetailsArray = this.foodAddForm.get('foodDetails') as FormArray;
    const index = foodDetailsArray.controls.findIndex((control) => {
      const expenseIdControl = control.get('expenseId');
      return expenseIdControl && expenseIdControl.value === selectedExpenseId;
    });

    if (index !== -1) {
      const selectedFoodDetailControl = foodDetailsArray.at(index);

      const obj = this.foodDetails.find((t) => t?._id === selectedExpenseId);
      if (obj) {
        selectedFoodDetailControl.get('amount').setValue(obj.amount);
      }
    }
  }

  getExpenseFood() {
    this.foods.getExpenseFood().subscribe((resp) => {
      if (resp.status == 'Success') {
        this.foodDetails = resp.data.lists;
      }
    });
  }

  submitFooddetails() {
    // console.log('data', this.foodAddForm);
    // console.log('this.bookingData ', this.bookingData);
    if (
      this.epf.requestedStatus.value === 'DELIVERED' &&
      !['CHECKED_IN', 'CHECKED_OUT'].includes(this.bookingData.bookingStatus)
    ) {
      this.common.presentToast('You can not mark as delivered before check in');
      return;
    }
    this.submitted = true;
    if (!this.foodAddForm.valid) {
      this.isSpinner = true;
      var data: any = {
        // title: this.epf.title.value,
        // quanity: +this.epf.quanity.value,
        // amount: +this.epf.amount.value,
        requestedStatus: this.epf.requestedStatus.value,
        items: this.epf.foodDetails.value.map((food) => ({
          quantity: parseInt(food.quantity),
          amount: parseInt(food.amount),
          expenseId: food.expenseId,
        })),
        booking: this.bookingData._id,
        mode: 'FOOD',
      };
      if (this.epf.deliveryTime.value) {
        data.expectedDeliveryTime = this.epf.deliveryTime.value + ':00+0530';
      }
      // console.log('datass', data);
      // return;
      this.bookingService.addFoodDetails(data).subscribe(
        (resp) => {
          if (resp.status == 'Success') {
            this.event.publishSomeData('booking');
            this.isSpinner = false;
            this.modalCtrl.dismiss();
          }
        },
        (error) => {
          this.isSpinner = false;
        }
      );
    }
  }

  closeModel() {
    this.modalCtrl.dismiss();
  }
}
