import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CommonService } from '../../providers/common';
import { FeedbackService } from '../../providers/feedback.service';

@Component({
  selector: 'app-feedback-questions-add',
  templateUrl: './feedback-questions-add.component.html',
  styleUrls: ['./feedback-questions-add.component.scss'],
})
export class FeedbackQuestionsAddComponent implements OnInit {

  @Input() item: any;
  feedbackQuestionForm: any = FormGroup;
  submitted = false;
  isLoading=false;

  constructor(private fb: FormBuilder, private feedback:FeedbackService,private maodalCtrl: ModalController, private common:CommonService) { }

  ngOnInit() {
    const title = this.item?.title ?? '';

    this.feedbackQuestionForm = this.fb.group({
      title: [title, Validators.required],

    });
    console.log(this.item);
  }


  get epf() { return this.feedbackQuestionForm.controls; }

  addFeedbackQuestion() {
    this.submitted=true;
    if (this.feedbackQuestionForm.valid) {
      const data = {
        title: this.epf.title.value,

      };
      this.isLoading=true;
      if (this.item?._id) {
        this.feedback.editFeedbackQuestions(data,this.item?._id).subscribe({
          next: (resp: any) => {
            if (resp.status === 'Success') {
              this.maodalCtrl.dismiss({
                response: true,
                data: resp?.data
              });
            }
          },
          error: (error: any) => {
            this.common.processError(error);
            this.isLoading = false;
          },
        });
      } else {
        this.feedback.addFeedbackQuestions(data).subscribe({
          next: (resp: any) => {
            if (resp.status === 'Success') {
              this.maodalCtrl.dismiss({
                response: true,
                data: resp?.data
              });
            }
          },
          error: (error: any) => {
            this.common.processError(error);
            this.isLoading = false;
          },
        });
      }
    }
  }

  closeModel() {
    this.maodalCtrl.dismiss();
  }



  getValue(e) {
    this.feedbackQuestionForm.get('isActive').patchValue(e.target.checked);
  }

}
