import { Component, ViewChild, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.scss'],
})
export class CameraModalComponent {
  @ViewChild('video') videoElement: ElementRef;
  video: any;

  constructor(private modalController: ModalController) { }

  async ionViewDidEnter() {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'environment' }
    });
    this.video = this.videoElement.nativeElement;
    this.video.srcObject = stream;
    this.video.play();
  }

  async capture() {

    const canvas = document.createElement('canvas');
    canvas.width = this.video.videoWidth;
    canvas.height = this.video.videoHeight;
    canvas.getContext('2d').drawImage(this.video, 0, 0);
    const image = canvas.toDataURL('image/png');



    await this.closeModal(image);
  }

  async closeModal(image?: string) {
    const stream = this.video.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop());
    await this.modalController.dismiss({
      role: 'done',
      data: image
    });
  }
}
