import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public api:Api) { }

  login(data){
    const endpoint='user/login'
    return this
    .api
    .post(endpoint,data)
  }

  registerFcm(data: any) {
    const endpoint = 'user/device/upsert';
    return this
      .api
      .post(endpoint, data)
  }


}
