import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CommonService } from '../../providers/common';
import { ProductService } from '../../providers/product.service';
import { TaxService } from '../../providers/tax.service';

@Component({
  selector: 'app-tax-add',
  templateUrl: './tax-add.page.html',
  styleUrls: ['./tax-add.page.scss'],
})
export class TaxAddPage implements OnInit {
  @Input() item: any;
  taxAddForm: any = FormGroup;
  productList: any = [];
  roomImage: any;
  submitted = false;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private tax: TaxService,
    private maodalCtrl: ModalController,
    private common: CommonService
  ) {}

  ngOnInit() {
    const title = this.item?.title ?? '';
    const percentage = this.item?.percentage ?? '';

    this.taxAddForm = this.fb.group({
      title: [title],
      percentage: [percentage, Validators.required],
      isActive: ['true'],
    });
  }

  get epf() {
    return this.taxAddForm.controls;
  }

  addRoomList() {
    this.submitted = true;
    // 

    // return;
    if (this.taxAddForm.valid) {
      const data: any = {
        title: this.epf.title.value ?  this.epf.title.value  : '',
        percentage: this.epf.percentage.value
          ? parseInt(this.epf.percentage.value)
          : '',
      };
      // if(this.epf.kitchenSetAmount.value!==''){
      //   data.kitchenSetAmount=this.epf.kitchenSetAmount.value;
      // }
      console.log('this.epf.title', data);
      this.isLoading = true;
      if (this.item?._id) {
        this.tax.editTax(data, this.item?._id).subscribe({
          next: (resp: any) => {
            if (resp.status === 'Success') {
              this.maodalCtrl.dismiss({
                response: true,
                data: resp?.data,
              });
            }
          },
          error: (error: any) => {
            this.common.processError(error);
            this.isLoading = false;
          },
        });
      } else {
        this.tax.addTax(data).subscribe({
          next: (resp: any) => {
            if (resp.status === 'Success') {
              this.maodalCtrl.dismiss({
                response: true,
                data: resp?.data,
              });
            }
          },
          error: (error: any) => {
            this.common.processError(error);
            this.isLoading = false;
          },
        });
      }
    }
  }

  closeModel() {
    this.maodalCtrl.dismiss();
  }

  getValue(e) {
    this.taxAddForm.get('isActive').patchValue(e.target.checked);
  }
}
